import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

//=== push 重複點擊轉跳路由問題 ===
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
//=== replace 重複點擊轉跳路由問題 ===
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/registerDone',
    name: 'RegisterDone',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterDone.vue')
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPwd.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue')
  },
  {
    path: '/accountEdit',
    name: 'AccountEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountEdit.vue')
  },
  {
    path: '/onair',
    name: 'Onair',
    component: () => import(/* webpackChunkName: "about" */ '../views/Onair.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "about" */ '../views/Live.vue')
  },
  {
    path: '/program',
    name: 'Program',
    component: () => import(/* webpackChunkName: "about" */ '../views/Program.vue')
  },
  {
    path: '/radio',
    name: 'Radio',
    component: () => import(/* webpackChunkName: "about" */ '../views/Radio.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/newsCont',
    redirect: '/news'
  },
  {
    path: '/newsCont/:id',
    name: 'NewsCont',
    component: () => import(/* webpackChunkName: "NewsCont" */ '../views/NewsCont/Index.vue')
  },
  // {
  //   path: '/newsCont',
  //   name: 'NewsCont',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NewsCont.vue')
  // },
  {
    path: '/newsProgram',
    name: 'NewsProgram',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsProgram.vue')
  },
  {
    path: '/hostList',
    redirect: '/radio'
  },
  {
    path: '/hostList/:id',
    name: 'HostList',
    component: () => import(/* webpackChunkName: "HostList" */ '../views/HostList/Index.vue')
  },
  // {
  //   path: '/hostList',
  //   name: 'HostList',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HostList.vue')
  // },
  {
    path: '/meteorology',
    name: 'Meteorology',
    component: () => import(/* webpackChunkName: "about" */ '../views/Meteorology.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  {
    path: '/searchCont',
    redirect: '/search'
  },
  {
    path: '/searchCont/:id',
    name: 'SearchCont',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchCont/Index.vue')
  },
  //測試iframe video
  {
    path: '/IframeVideo',
    name: 'IframeVideo',
    component: () => import(/* webpackChunkName: "about" */ '../views/IframeVideo.vue')
  },
  //測試第三方登入
  {
    path: '/loginTest',
    name: 'LoginTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginTest.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router

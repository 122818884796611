import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      name: '-',
      acc: '-',
      clan: '-',
      birth: '-',
      Gender: '-',
      mail: '-',
      phone: '-',
      userToken: '',
    },
    homeAdm: true,
    openPage: true,
  },
  getters: {
    userInfoGet(state) {
      return state.userInfo
    },
    homeAdmGet(state) {
      return state.homeAdm
    },
    homeOpenPageGet(state) {
      return state.openPage
    }
  },
  mutations: {
    SETUSERINFO(state, val) {
      state.userInfo = val;
    },
    SETHOMEADM(state, val) {
      state.homeAdm = val;
    },
    SETHOMEOPENPAGE(state, val) {
      state.openPage = val;
    },
  },
  actions: {
    GETTOUSERINFO(context, Info) {
      console.log(context);
      console.log(Info);
      //這裡接收到之後 交給 mutations 的 SETIMGID 去設定給 state 傳來的 Id 值
      context.commit("SETUSERINFO", Info);
    },
    GETTOHOMEADM(context, Info) {
      console.log(context);
      console.log(Info);
      //這裡接收到之後 交給 mutations 的 SETIMGID 去設定給 state 傳來的 Id 值
      context.commit("SETHOMEADM", Info);
    },
  },
  modules: {
  }
})

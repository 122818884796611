import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/bundle';

//=== css ===
import "@/assets/css/jquery.mCustomScrollbar.min.css"
import "@/assets/css/sty.css"

//=== js ===
import $ from 'jquery'
Vue.prototype.$ = $;

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
